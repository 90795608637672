import '../styles/globals.scss'
import '../styles/fonts.scss'
import type {AppProps} from 'next/app'
import Head from 'next/head'

function MyApp({Component, pageProps}: AppProps) {
	return (
		<>
			<Head>
				<meta charSet="utf-8" />
				{process.env.NODE_ENV === 'production' ? (
					<meta name="robots" content="index, follow" />
				) : (
					<meta name="robots" content="noindex, nofollow" />
				)}
				<meta name="viewport" content="initial-scale=1.0, width=device-width" />
				<link rel="shortcut icon" href="./favicon.png" />
				<title>#SIC2021 - Quiz</title>
				<meta property="og:type" content="website" />
				<meta property="og:url" content="https://sic2021.sport.vlaanderen/" />
				<meta property="og:title" content="#SIC2021 - Quiz" />
				<meta
					name="description"
					content="Samen op koers is het vierde congres dat Sport Vlaanderen organiseert rond innovatie."
				/>
				<meta
					property="og:description"
					content="Samen op koers is het vierde congres dat Sport Vlaanderen organiseert rond innovatie."
				/>
				<meta property="og:image" content="./header-logo-black.png" />
				<meta property="og:image:width" content="512" />
				<meta property="og:image:height" content="295" />
			</Head>
			<Component {...pageProps} />
		</>
	)
}
export default MyApp
